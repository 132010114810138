import React, { useState } from 'react';
import './App.css';
import './../node_modules/slick-carousel/slick/slick.css';
import './../node_modules/slick-carousel/slick/slick-theme.css';
import { Navbar } from './components/navbar';
import { PrayerRequestList } from './components/prayerrequestlist';
import BackToTop from '@uiw/react-back-to-top';
import { AddPrayerRequestModal } from './components/addprayerrequestmodal';

const App: React.FC = (): JSX.Element => {
  const [loadCount, setLoadCount] = useState<number>(1);
  const [take, setTake] = useState<number>(20);
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [showAddPrayerRequestModal, setShowAddPrayerRequestModal] = useState<boolean>(false);

  const handleSearchFilterChange = (filter: string): void => {
    setSearchFilter(filter);
  };

  const handleAddPrayerRequestButtonClick = (): void => {
    setShowAddPrayerRequestModal(true);
  };

  const handleAddPrayerRequestModalActionClick = (refresh: boolean): void => {
    if (refresh) {
      setLoadCount(loadCount + 1);
    }
    setShowAddPrayerRequestModal(false);
  };

  const handleMoreButtonClick = (): void => {
    setTake(take + 20);
  };

  return (
    <>
      <div className="App">
        <Navbar
          onAddPrayerRequestButtonClick={handleAddPrayerRequestButtonClick}
          onSearchFilterChange={handleSearchFilterChange}
        />
        <PrayerRequestList
          loadCount={loadCount}
          searchFilter={searchFilter}
          onAddPrayerRequestButtonClick={handleAddPrayerRequestButtonClick}
          onMoreButtonClick={handleMoreButtonClick}
          take={take}
        />
        <BackToTop size={50} strokeWidth={5}>Top</BackToTop>
      </div>
      {showAddPrayerRequestModal &&
        <AddPrayerRequestModal 
          onActionClick={handleAddPrayerRequestModalActionClick}
        />
      }
    </>
  );
};

export default App;