import React, { useEffect, useState } from 'react';
import { Card } from './card';
import moment from 'moment';
import ShowMoreText from 'react-show-more-text';
import Highlighter from 'react-highlight-words';
import { Triangle } from 'react-loader-spinner';
import { NoPrayerRequestReason, NoPrayerRequests } from './noprayerrequests';

interface IGetIntercessionsResponse {
    groupedIntercessions: IIntercessionsDictionaryEntry[];
    moreAvailable: boolean;
}

interface IIntercessionsDictionaryEntry {
    key: string;
    value: IIntercession[];
}

interface IIntercession {
    id: number;
    intercessorFirstName: string;
    intercessorLastName: string;
    intercessorEmail: string;
    intercessorPhone: string;
    intercessorAddress1: string;
    intercessorAddress2: string;
    intercessorCity: string;
    intercessorState: string;
    intercessorZip: string;
    beneficiaryFirstName: string;
    beneficiaryLastName: string;
    beneficiaryAge: number;
    beneficiaryEmail: string;
    beneficiaryPhone: string;
    beneficiaryAddress1: string;
    beneficiaryAddress2: string;
    beneficiaryCity: string;
    beneficiaryState: string;
    beneficiaryZip: string;
    prayerRequest: string;
    createdDate: string;
}

export interface IPrayerRequestListProps {
    loadCount: number;
    searchFilter: string;
    take: number;
    onAddPrayerRequestButtonClick: () => void;
    onMoreButtonClick: () => void;
}

export const PrayerRequestList: React.FC<IPrayerRequestListProps> = (props): JSX.Element => {
    const [take, setTake] = useState<number>(0);
    const [dataFetched, setDataFetched] = useState<boolean>(false);
    const [prayerRequests, setPrayerRequests] = useState<IIntercessionsDictionaryEntry[]>([]);
    const [searchWords, setSearchWords] = useState<string[]>([]);
    const [doShowMoreButton, setDoShowMoreButton] = useState<boolean>(true);

    useEffect(() => {
        setSearchWords(props.searchFilter.trim().toLowerCase().split(' '));
        setDataFetched(false);
        const fetchPrayerRequests = async () => {
            try {
                const response = await fetch(`https://prayerrequesthub.com/api/Intercessions?searchFilter=${encodeURIComponent(props.searchFilter ? props.searchFilter : ' ')}&take=${props.take}`);
                const data: IGetIntercessionsResponse = await response.json();

                if (!data || !data.moreAvailable) {
                    setDoShowMoreButton(false);
                }

                setPrayerRequests(data.groupedIntercessions);
                setDataFetched(true);
                if (take !== props.take){
                    document.getElementById(`${props.take - 1}PrayerRequestDateGroup`)?.scrollIntoView();
                    setTake(props.take);
                } else {
                    window.scrollTo({ top: 0 });
                }
            } catch (error) {
                console.error(error);
            }
        };

        const fetchData = setTimeout(fetchPrayerRequests, 500);

        return () => clearTimeout(fetchData);
    }, [props.searchFilter, props.loadCount, props.take]);

    return (
        <>
            {!dataFetched &&
                <div className="w-full min-h-screen bg-gray-600 flex items-center justify-center">
                    <Triangle color='white' />
                </div>
            }
            {(dataFetched || take !== props.take) &&
                <div className="w-full min-h-screen bg-gray-600 text-white pt-16">
                    <div className="lg:px-60 md:px-15 sm:px-2">
                        {prayerRequests != null && prayerRequests.length > 0 && prayerRequests.map((entry: IIntercessionsDictionaryEntry, i: number) => {
                            if (entry.value.length > 0)
                                return (
                                    <div key={`${i}_prayerrequestsdategroup`} id={`${i}PrayerRequestsDateGroup`} className="py-3">
                                        <div className="content-start">{moment(entry.key).format('dddd, MMMM DD, YYYY')}</div>
                                        {entry.value.map((intercession: IIntercession, j: number) => {
                                            const prayerRequestContainsSearchWords: boolean = searchWords.every(w => !!w) && searchWords.some(w => intercession.prayerRequest.toLowerCase().indexOf(w) > -1);
                                            return (
                                                <div className="my-2" key={`${j}_prayerrequest`}>
                                                    <Card>
                                                        <>
                                                            {intercession.beneficiaryFirstName &&
                                                                <div className="text-sm">
                                                                    Prayers requested for
                                                                    <span className="font-bold text-lg ml-1">
                                                                        <Highlighter
                                                                            highlightClassName='search-highlight'
                                                                            searchWords={searchWords}
                                                                            autoEscape={true}
                                                                            textToHighlight={`${intercession.beneficiaryFirstName} ${intercession.beneficiaryLastName}`}
                                                                        />
                                                                    </span>
                                                                </div>
                                                            }
                                                            <div className={`${intercession.beneficiaryFirstName && 'mt-3'} bg-slate-700 p-2 rounded-lg`}>
                                                                {prayerRequestContainsSearchWords &&
                                                                    <Highlighter
                                                                        highlightClassName='search-highlight'
                                                                        searchWords={searchWords}
                                                                        autoEscape={true}
                                                                        textToHighlight={intercession.prayerRequest}
                                                                    />
                                                                }
                                                                {!prayerRequestContainsSearchWords &&
                                                                    <ShowMoreText
                                                                        more="[show more]"
                                                                        less="[show less]"
                                                                        lines={3}
                                                                    >
                                                                        {intercession.prayerRequest}
                                                                    </ShowMoreText>
                                                                }
                                                            </div>
                                                        </>
                                                    </Card>
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                        })}
                        {doShowMoreButton && 
                            <div className="flex items-center justify-center pb-5">
                            <button
                                type="button"
                                className="relative inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                onClick={props.onMoreButtonClick}
                            >
                                MORE
                            </button>
                        </div>
                        }
                        {dataFetched && (!prayerRequests || prayerRequests.length === 0) &&
                            <NoPrayerRequests 
                                reason={!searchWords || !searchWords.filter(w => !!w.trim().length).length ? NoPrayerRequestReason.NoData : NoPrayerRequestReason.NoMatchFromSearch}
                                onAddPrayerRequestButtonClick={props.onAddPrayerRequestButtonClick}
                            />
                        }
                    </div>
                </div>
            }
        </>
    );
};