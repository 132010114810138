import React from 'react';
import { IPassage, IVerse } from '../passages';

export interface ISliderPassageProps {
    passage: IPassage;
}

export const SliderPassage: React.FC<ISliderPassageProps> = (props): JSX.Element => {
    const { book, chapter, verses } = props.passage;

    return (
        <div className="text-justify">
            <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300 px-1">
                {book} {chapter}:{verses.length === 1 ? verses[0].number : `${verses[0].number}-${verses[0].number + verses.length - 1}`}
                <br />
                <em>
                    {verses.map((verse: IVerse, i: number) => <span key={i}><sup><small>{verse.number}</small></sup>{verse.text}{verse.breakAfter && <br />}</span>)}
                </em>
            </p>
        </div>
    );
};