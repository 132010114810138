import React from 'react';
import { PlusIcon } from '@heroicons/react/24/solid';
import Slider from 'react-slick';
import { SliderPassage } from './sliderpassage';
import { passages, IPassage } from '../passages';

export enum NoPrayerRequestReason {
    NoMatchFromSearch,
    NoData
}

export interface INoPrayerRequestsProps {
    reason: NoPrayerRequestReason;
    onAddPrayerRequestButtonClick: () => void;
}

export const NoPrayerRequests: React.FC<INoPrayerRequestsProps> = (props): JSX.Element => {
    return (
        <div className="bg-gray-600">
            <div className="mx-auto max-w-7xl py-4 sm:px-6 sm:py-12 lg:px-8">
                <div className="relative isolate overflow-hidden bg-gray-900 px-6 py-24 text-center shadow-2xl sm:rounded-3xl sm:px-16">
                    <h2 className="mx-auto max-w-2xl text-3xl font-bold tracking-tight text-white sm:text-4xl">
                        {props.reason === NoPrayerRequestReason.NoData && <>There are currently no prayer requests.</>}
                        {props.reason === NoPrayerRequestReason.NoMatchFromSearch && <>No prayer requests found<br/>matching your search.</>}
                    </h2>
                    <Slider
                        dots={true}
                        infinite={true}
                        slidesToShow={1}
                        slidesToScroll={1}
                        autoplay={true}
                        speed={750}
                        autoplaySpeed={15000}
                        cssEase='linear'
                        arrows={false}
                    >
                        {passages.map((passage: IPassage,i: number) => <SliderPassage key={i} passage={passage} />)}
                    </Slider>
                    <div className="mt-10 flex items-center justify-center gap-x-6">
                        <button
                            type="button"
                            className="relative inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            onClick={props.onAddPrayerRequestButtonClick}
                        >
                            <PlusIcon className="h-5 w-5" aria-hidden="true" /> New Prayer Request
                        </button>
                    </div>
                    <svg
                        viewBox="0 0 1024 1024"
                        className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
                        aria-hidden="true"
                    >
                        <circle cx={512} cy={512} r={512} fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)" fillOpacity="0.7" />
                        <defs>
                            <radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
                                <stop stopColor="#7775D6" />
                                <stop offset={1} stopColor="#E935C1" />
                            </radialGradient>
                        </defs>
                    </svg>
                </div>
            </div>
        </div>
    );
};