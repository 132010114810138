import React, { ChangeEvent } from 'react';

export interface IDropdownProps {
    items: any[];
    valueProp: string;
    textProp: string;
    selectedValue?: any;
    title?: string;
    optional?: boolean;
    label?: string;
    useLabel?: boolean;
    onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
}

export const Dropdown: React.FC<IDropdownProps> = (props): JSX.Element => {
    return (
        <div className="form-control w-full max-w-xs">
            {props.useLabel &&
                <label className="label">
                    {props.label &&
                        <span className="label-text text-white">{props.label}</span>
                    }
                    {props.optional &&
                        <span className="label-text-alt">Optional</span>
                    }
                </label>
            }
            <select 
                className="select select-bordered select-sm mt-[-5px]" 
                title={props.title}
                onChange={props.onChange}
            >
                {props.items.map((item: any, i: number) => 
                    <option 
                        key={`${item[props.valueProp]}_|_${i}`}
                        selected={props.selectedValue && item[props.valueProp] === props.selectedValue} 
                        value={item[props.valueProp]}
                    >
                        {item[props.textProp]}
                    </option>
                )}
            </select>
        </div>
    );
};