import React from 'react';

export interface IUpChevronProps {
    onClick?: () => void;
    className?: string;
}

export const UpChevron: React.FC<IUpChevronProps> = (props): JSX.Element => {
    const handleClick = (): void => {
        if (props.onClick) {
            props.onClick();
        }
    };

    return (
        <div onClick={handleClick} className={props.className}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l7.5-7.5 7.5 7.5m-15 6l7.5-7.5 7.5 7.5" />
            </svg>
        </div>
    );
};