import React, { ChangeEvent } from 'react';

export interface ITextInputProps {
    type?: 'text' | 'email' | 'tel';
    useLabel?: boolean;
    label?: string;
    optional?: boolean;
    placeholder?: string;
    title?: string;
    value?: any;
    maxLength?: number;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const TextInput: React.FC<ITextInputProps> = (props): JSX.Element => {
    return (
        <div className="form-control w-full">
            {props.useLabel &&
                <label className="label">
                    <span className="label-text text-white">{props.label}</span>
                    {props.optional &&
                        <span className="label-text-alt">Optional</span>
                    }
                </label>
            }
            <input type={props.type || "text"} placeholder={props.placeholder} className="input input-bordered w-full input-sm mt-[-5px]" value={props.value} onChange={props.onChange} title={props.title} maxLength={props.maxLength} />
        </div>
        // <div>
        //     {props.useLabel &&
        //         <div className="flex justify-between">
        //             {props.label && 
        //                 <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-400">
        //                     {props.label}
        //                 </label>
        //             }
        //             {props.optional &&
        //                 <span className="text-sm leading-6 text-gray-500" id="email-optional">
        //                     Optional
        //                 </span>
        //             }
        //         </div>
        //     }
        //     <div className="mt-0">
        //         <input
        //             type={props.type || "text"}
        //             className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        //             placeholder={props.placeholder}
        //         />
        //     </div>
        // </div>
    );
};