import React, { ChangeEvent, useEffect, useState } from 'react';
import { Modal } from './modal';
import { TextInput } from './textinput';
import { CardWithHeader } from './cardwithheader';
import { Dropdown } from './dropdown';
import { Resources } from '../resources';
import { NumberInput } from './numberinput';
import { Textarea } from './textarea';
import { Toggle } from './toggle';

interface IIntercession {
    id?: number;
    intercessorFirstName?: string;
    intercessorLastName?: string;
    intercessorEmail?: string;
    intercessorPhone?: string;
    intercessorAddress1?: string;
    intercessorAddress2?: string;
    intercessorCity?: string;
    intercessorState?: string;
    intercessorZip?: string;
    beneficiaryFirstName?: string;
    beneficiaryLastName?: string;
    beneficiaryAge?: number;
    beneficiaryEmail?: string;
    beneficiaryPhone?: string;
    beneficiaryAddress1?: string;
    beneficiaryAddress2?: string;
    beneficiaryCity?: string;
    beneficiaryState?: string;
    beneficiaryZip?: string;
    prayerRequest?: string;
    createdDate?: string;
}

export interface IAddPrayerRequestModalProps {
    onActionClick: (doSave: boolean) => void;
}

export const AddPrayerRequestModal: React.FC<IAddPrayerRequestModalProps> = (props): JSX.Element => {
    const [doSubmit, setDoSubmit] = useState<boolean>(false);
    const [disabledSubmit, setDisabledSubmit] = useState<boolean>(true);
    const [requestingPrayersForSelf, setRequestingPrayersForSelf] = useState<boolean>(false);
    const [formState, setFormState] = useState<IIntercession>({
        intercessorFirstName: undefined,
        intercessorLastName: undefined,
        intercessorEmail: undefined,
        intercessorPhone: undefined,
        intercessorAddress1: undefined,
        intercessorAddress2: undefined,
        intercessorCity: undefined,
        intercessorState: undefined,
        intercessorZip: undefined,
        beneficiaryFirstName: undefined,
        beneficiaryLastName: undefined,
        beneficiaryEmail: undefined,
        beneficiaryPhone: undefined,
        beneficiaryAge: undefined,
        beneficiaryAddress1: undefined,
        beneficiaryAddress2: undefined,
        beneficiaryCity: undefined,
        beneficiaryState: undefined,
        beneficiaryZip: undefined,
        prayerRequest: undefined,
        createdDate: undefined
    });

    const usStates: {value: string; text: string;}[] = Resources.USStates.map(state => { 
        return { value: state.name, text: state.name }; 
    });

    usStates.unshift({ value: '', text: '' });

    const handleActionClick = (doSave: boolean): void => {
        if (doSave) {
            setDoSubmit(true);
        } else {
            props.onActionClick(false);
        }
    };

    const handleInputChange = (name: string, value: string): void => {
        setFormState({ ...formState, [name]: value });
    };

    const handlePrayerRequestBeneficiaryToggleChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setRequestingPrayersForSelf(e.currentTarget.checked);
    };

    useEffect(() => {
        if (formState.prayerRequest && formState.prayerRequest.length > 0) {
            setDisabledSubmit(false);
        } else {
            setDisabledSubmit(true);
        }
    }, [formState.prayerRequest]);

    useEffect(() => {
        if (doSubmit) {
            const post = async () => {
                try {
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(formState)
                    };
                    const response = await fetch(`https://prayerrequesthub.com/api/Intercessions/add`, requestOptions);
                    const data = await response.json();

                    if (data) {
                        props.onActionClick(true);
                    } else {
                        // show save error
                    }
                } catch (error) {
                    console.error(error);
                }
            };

            post();
        }
    }, [doSubmit]);

    return (
        <Modal
            onActionClick={handleActionClick}
            disableSubmit={disabledSubmit}
        >
            <div className="grid grid-cols-12">
                <div className="col-span-12">
                    <Toggle 
                        label="Are you requesting prayers for yourself?"
                        selectedOptionLabel={requestingPrayersForSelf 
                            ? 'Yes, I would like for others to pray for me' 
                            : 'No, I would like for others to pray for someone I know'}
                        checked={requestingPrayersForSelf}
                        onChange={handlePrayerRequestBeneficiaryToggleChange}
                    />
                </div>
            </div>
            <CardWithHeader
                title={requestingPrayersForSelf ? 'Your Information' : 'Beneficiary Information'}
                collapsible={true}
                collapsed={true}
            >
                <div className="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-4">
                    <div>
                        <TextInput 
                            useLabel={true}
                            label="First Name"
                            optional={true}
                            title="First Name of person prayer is needed for"
                            maxLength={50}
                            value={formState.beneficiaryFirstName}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                handleInputChange('beneficiaryFirstName', e.currentTarget.value);
                            }}
                        />
                    </div>
                    <div>
                        <TextInput 
                            useLabel={true}
                            label="Last Name"
                            optional={true}
                            title="Last Name of person prayer is needed for"
                            maxLength={50}
                            value={formState.beneficiaryLastName}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                handleInputChange('beneficiaryLastName', e.currentTarget.value);
                            }}
                        />
                    </div>
                    <div>
                        <TextInput 
                            useLabel={true}
                            label="Email"
                            optional={true}
                            title="Email of person prayer is needed for"
                            type="email"
                            maxLength={100}
                            value={formState.beneficiaryEmail}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                handleInputChange('beneficiaryEmail', e.currentTarget.value);
                            }}
                        />
                    </div>
                    <div>
                        <TextInput
                            useLabel={true}
                            label="Phone"
                            optional={true}
                            title="Phone of person prayer is needed for"
                            type="tel"
                            maxLength={20}
                            value={formState.beneficiaryPhone}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                handleInputChange('beneficiaryPhone', e.currentTarget.value);
                            }}
                        />
                    </div>
                </div>
                <div className="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-4">
                    <div>
                        <NumberInput 
                            useLabel={true}
                            label="Age"
                            optional={true}
                            title="Age of person prayer is needed for"
                            min={0}
                            max={150}
                            value={formState.beneficiaryAge}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                handleInputChange('beneficiaryAge', e.currentTarget.value);
                            }}
                        />
                    </div>
                    <div>
                        <TextInput 
                            useLabel={true}
                            label="Street Address"
                            optional={true}
                            title="Street address of person prayer is needed for"
                            maxLength={200}
                            value={formState.beneficiaryAddress1}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                handleInputChange('beneficiaryAddress1', e.currentTarget.value);
                            }}
                        />
                    </div>
                    <div>
                        <TextInput 
                            useLabel={true}
                            label="Apt/Suite #"
                            optional={true}
                            title="Apartment/Suite # of person prayer is needed for"
                            maxLength={200}
                            value={formState.beneficiaryAddress2}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                handleInputChange('beneficiaryAddress2', e.currentTarget.value);
                            }}
                        />
                    </div>
                </div>
                <div className="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-4">
                    <div>
                        <TextInput 
                            useLabel={true}
                            label="City"
                            optional={true}
                            title="City of person prayer is needed for"
                            maxLength={50}
                            value={formState.beneficiaryCity}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                handleInputChange('beneficiaryCity', e.currentTarget.value);
                            }}
                        />
                    </div>
                    <div>
                        <Dropdown 
                            items={usStates}
                            valueProp="value"
                            textProp="text"
                            useLabel={true}
                            label="State"
                            optional={true}
                            selectedValue={formState.beneficiaryState}
                            title="State of person prayer is needed for"
                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                handleInputChange('beneficiaryState', e.currentTarget.value);
                            }}
                        />
                    </div>
                    <div>
                        <TextInput 
                            useLabel={true}
                            label="Zip Code"
                            optional={true}
                            title="Zip Code of person prayer is needed for"
                            maxLength={20}
                            value={formState.beneficiaryZip}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                handleInputChange('beneficiaryZip', e.currentTarget.value);
                            }}
                        />
                    </div>
                </div>
            </CardWithHeader>
            {!requestingPrayersForSelf &&
                <CardWithHeader
                    title='Your Information'
                    collapsible={true}
                    collapsed={true}
                    className="mt-4"
                >
                    <div className="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-4">
                        <div>
                            <TextInput 
                                useLabel={true}
                                label="First Name"
                                optional={true}
                                title="First Name of person prayer is needed for"
                                maxLength={50}
                                value={formState.intercessorFirstName}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    handleInputChange('intercessorFirstName', e.currentTarget.value);
                                }}
                            />
                        </div>
                        <div>
                            <TextInput 
                                useLabel={true}
                                label="Last Name"
                                optional={true}
                                title="Last Name of person prayer is needed for"
                                maxLength={50}
                                value={formState.intercessorLastName}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    handleInputChange('intercessorLastName', e.currentTarget.value);
                                }}
                            />
                        </div>
                        <div>
                            <TextInput 
                                useLabel={true}
                                label="Email"
                                optional={true}
                                title="Email of person prayer is needed for"
                                type="email"
                                maxLength={100}
                                value={formState.intercessorEmail}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    handleInputChange('intercessorEmail', e.currentTarget.value);
                                }}
                            />
                        </div>
                        <div>
                            <TextInput
                                useLabel={true}
                                label="Phone"
                                optional={true}
                                title="Phone of person prayer is needed for"
                                type="tel"
                                maxLength={20}
                                value={formState.intercessorPhone}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    handleInputChange('intercessorPhone', e.currentTarget.value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-4">
                        <div>
                            <TextInput 
                                useLabel={true}
                                label="Street Address"
                                optional={true}
                                title="Street address of person prayer is needed for"
                                maxLength={200}
                                value={formState.intercessorAddress1}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    handleInputChange('intercessorAddress1', e.currentTarget.value);
                                }}
                            />
                        </div>
                        <div>
                            <TextInput 
                                useLabel={true}
                                label="Apt/Suite #"
                                optional={true}
                                title="Apartment/Suite # of person prayer is needed for"
                                maxLength={200}
                                value={formState.intercessorAddress2}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    handleInputChange('intercessorAddress2', e.currentTarget.value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-4">
                        <div>
                            <TextInput 
                                useLabel={true}
                                label="City"
                                optional={true}
                                title="City of person prayer is needed for"
                                maxLength={50}
                                value={formState.intercessorCity}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    handleInputChange('intercessorCity', e.currentTarget.value);
                                }}
                            />
                        </div>
                        <div>
                            <Dropdown 
                                items={usStates}
                                valueProp="value"
                                textProp="text"
                                useLabel={true}
                                label="State"
                                optional={true}
                                selectedValue={formState.intercessorState}
                                title="State of person prayer is needed for"
                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                    handleInputChange('intercessorState', e.currentTarget.value);
                                }}
                            />
                        </div>
                        <div>
                            <TextInput 
                                useLabel={true}
                                label="Zip Code"
                                optional={true}
                                title="Zip Code of person prayer is needed for"
                                maxLength={20}
                                value={formState.intercessorZip}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    handleInputChange('intercessorZip', e.currentTarget.value);
                                }}
                            />
                        </div>
                    </div>
                </CardWithHeader>
            }
            <CardWithHeader
                title="Prayer Request"
                className="mt-4"
            >
                <div>
                    <label className="label-text-alt text-[14px]">Enter your prayer request here. Please be as specific as you can be.</label>
                </div>
                <Textarea 
                    useLabel={true}
                    label="Prayer Request"
                    title="Prayer Request"
                    value={formState.prayerRequest}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                        handleInputChange('prayerRequest', e.currentTarget.value);
                    }}
                />
            </CardWithHeader>
        </Modal>
    );
};