import React, { ChangeEvent } from 'react';

export interface IToggleProps {
    checked?: boolean;
    label?: string;
    selectedOptionLabel?: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const Toggle: React.FC<IToggleProps> = (props): JSX.Element => {
    return (
        <div className="form-control">
            <div className="flex">
                <div>
                    <label className="label cursor-pointer">
                        <div className={`grid ${props.label ? 'lg:md:grid-cols-12' : 'lg:md:grid-cols-1'} sm:grid-cols-1`}>
                            {props.label &&
                                <div className="lg:md:col-span-9">
                                    <span className="label-text">{props.label}</span>
                                </div>
                            }
                            <div className="lg:md:col-span-3">
                                <input type="checkbox" className="toggle ml-2" checked={props.checked} onChange={props.onChange} />
                            </div>   
                        </div>
                    </label>
                </div>
                {props.selectedOptionLabel &&
                    <div className="pt-1.5">
                        <div className="label-text pt-1">{props.selectedOptionLabel}</div>
                    </div>
                }
            </div>
        </div>
        
    );
};