import React, { ChangeEvent } from 'react';

export interface INumberInputProps {
    useLabel?: boolean;
    label?: string;
    optional?: boolean;
    placeholder?: string;
    title?: string;
    value?: any;
    max?: number;
    min?: number;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const NumberInput: React.FC<INumberInputProps> = (props): JSX.Element => {
    return (
        <div className="form-control w-full">
            {props.useLabel &&
                <label className="label">
                    <span className="label-text text-white">{props.label}</span>
                    {props.optional &&
                        <span className="label-text-alt">Optional</span>
                    }
                </label>
            }
            <input type="number" placeholder={props.placeholder} className="input input-bordered w-full input-sm mt-[-5px]" value={props.value} onChange={props.onChange} title={props.title} max={props.max} min={props.min} />
        </div>
    );
};