export interface IPassage {
    book: string;
    chapter: number;
    verses: IVerse[];
}

export interface IVerse {
    number: number;
    text: string;
    breakAfter?: boolean;
}

export const passages: IPassage[] = [
    {
        book: '1 Thessalonians',
        chapter: 3,
        verses: [
            {
                number: 9,
                text: 'For what thanksgiving can we return to God for you, for all the joy that we feel for your sake before our God, '
            },
            {
                number: 10,
                text: 'as we pray most earnestly night and day that we may see you face to face and supply what is lacking in your faith?',
                breakAfter: true
            },
            {
                number: 11,
                text: 'Now may our God and Father himself, and our Lord Jesus, direct our way to you, '
            },
            {
                number: 12,
                text: 'and may the Lord make you increase and abound in love for one another and for all, as we do for you, '
            },
            {
                number: 13,
                text: 'so that he may establish your hearts blameless in holiness before our God and Father, at the coming of our Lord Jesus with all his saints.'
            }
        ]
    },
    {
        book: 'Philippians',
        chapter: 1,
        verses: [
            {
                number: 9,
                text: 'And it is my prayer that your love may abound more and more, with knowledge and all discernment, '
            },
            {
                number: 10,
                text: 'so that you may approve what is excellent, and so be pure and blameless for the day of Christ, '
            },
            {
                number: 11,
                text: 'filled with the fruit of righteousness that comes through Jesus Christ, to the glory and praise of God.'
            }
        ]
    },
    {
        book: 'Colossians',
        chapter: 1,
        verses: [
            {
                number: 9,
                text: 'And so, from the day we heard, we have not ceased to pray for you, asking that you may be filled with the knowledge of his will in all spiritual wisdom and understanding, '
            },
            {
                number: 10,
                text: 'so that you may approve what is excellent, and so be pure and blameless for the day of Christ, '
            },
            {
                number: 11,
                text: 'being strengthened with all power, according to his glorious might, for all endurance and patience with joy; '
            },
            {
                number: 12,
                text: 'giving thanks to the Father, who has qualified you to share in the inheritance of the saints in light. '
            },
            {
                number: 13,
                text: 'He has delivered us from the domain of darkness and transferred us to the kingdom of his beloved Son, '
            },
            {
                number: 14,
                text: 'in whom we have redemption, the forgiveness of sins.'
            }
        ]
    },
    {
        book: 'Philippians',
        chapter: 4,
        verses: [
            {
                number: 6,
                text: 'do not be anxious about anything, but in everything by prayer and supplication with thansgiving let your requests be made known to God.'
            }
        ]
    },
    {
        book: 'Romans',
        chapter: 8,
        verses: [
            {
                number: 26,
                text: 'Likewise the Spirit helps us in our weakness. For we do not know what to pray for as we ought, but the Spirit himself intercedes for us with groanings too deep for words.'
            }
        ]
    },
    {
        book: 'James',
        chapter: 5,
        verses: [
            {
                number: 16,
                text: 'Therefore, confess your sins to one another and pray for one another, that you may be healed. The prayer of a righteous person has great power as it is working.'
            }
        ]
    }
];