import React, { ChangeEvent } from 'react';

export interface ITextareaProps {
    useLabel?: boolean;
    label?: string;
    optional?: boolean;
    placeholder?: string;
    title?: string;
    value?: string;
    onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

export const Textarea: React.FC<ITextareaProps> = (props): JSX.Element => {
    return (
        <div className="form-control">
            {props.useLabel &&
                <label className="label">
                    {props.label &&
                        <span className="label-text text-white">{props.label}</span>
                    }
                    {props.optional &&
                        <span className="label-text-alt">Optional</span>
                    }
                </label>
            }
            <textarea 
                className="textarea textarea-bordered h-24" 
                placeholder={props.placeholder} 
                onChange={props.onChange}
            >
                {props.value}
            </textarea>
        </div>
    );
};