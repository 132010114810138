import React, { PropsWithChildren, useState } from 'react';
import { DownChevron } from './downchevron';
import { UpChevron } from './upchevron';

export interface ICardWithHeaderProps extends PropsWithChildren {
    title: string;
    collapsible?: boolean;
    collapsed?: boolean;
    className?: string;
}

export const CardWithHeader: React.FC<ICardWithHeaderProps> = (props): JSX.Element => {
    const [collapsed, setCollapsed] = useState<boolean>(props.collapsed || false);

    return (
        <div className={`divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-500 shadow w-full ${props.className}`}>
            <div className={`sm:px-6 px-4 py-2 text-white ${!!props.collapsible && 'cursor-pointer'}`} onClick={() => setCollapsed((prevCollapsed) => (!!props.collapsible && !prevCollapsed))}>
                {props.title}
                {props.collapsible &&
                    <div className="float-right">
                        {collapsed &&
                            <DownChevron
                            />
                        }
                        {!collapsed &&
                            <UpChevron
                            />
                        }
                    </div>
                }
            </div>
            {!collapsed &&
                <div className="px-4 py-5 sm:p-6">
                    {props.children}
                </div>
            }
        </div>
    );
};