import React from 'react';

export interface ICardProps {
    children: JSX.Element;
}

export const Card: React.FC<ICardProps> = (props): JSX.Element => {
    return (
        <div className="overflow-hidden rounded-lg bg-gray-800 shadow hover:shadow-gray-500 hover:shadow-md">
            <div className="px-4 py-5 sm:p-6 text-white">
                {props.children}
            </div>
        </div>
    )
};