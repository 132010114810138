import React, { ChangeEvent, KeyboardEvent } from 'react';
import { Disclosure } from '@headlessui/react';
import { PlusIcon, MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import prayinghands from '../prayinghands.svg';

export interface INavbarProps {
    onAddPrayerRequestButtonClick: () => void;
    onSearchFilterChange: (searchFilter: string) => void;
}

export const Navbar: React.FC<INavbarProps> = (props): JSX.Element => {
    const handleSearchFilterChange = (e: KeyboardEvent<HTMLInputElement> | ChangeEvent<HTMLInputElement>): void => {
        props.onSearchFilterChange(e.currentTarget.value);
    };

    return (
        <>
            <Disclosure as="nav" className="bg-gray-800 fixed w-full">
                {({ open }) => (
                    <>
                        <div className="mx-auto max-w-7xl px-2 sm:px-4 lg:px-8">
                            <div className="relative flex h-16 items-center justify-between">
                                <div className="flex items-center px-2 lg:px-0">
                                    <div className="flex-shrink-0">
                                        <img
                                            className="h-20 w-auto text-indigo-600"
                                            src={prayinghands}
                                            alt="Prayer Request Hub"
                                        />
                                    </div>
                                    <div className="hidden lg:ml-6 lg:block">
                                        <div className="flex space-x-4">
                                            <a
                                                href="https://firstbaptistmocksville.org"
                                                target="_blank"
                                                className="inline-flex items-center px-1 pt-1 text-sm font-medium text-white lg:after:content-['First_Baptist_Church_Mocksville'] md:after:content-['FBC_Mocksville'] sm:after:content-['FBC']"
                                                rel="noreferrer"
                                            >
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-1 justify-center px-2 lg:ml-6">
                                    <div className="w-full max-w-lg lg:max-w-xs">
                                        <label htmlFor="search" className="sr-only">
                                            Search
                                        </label>
                                        <div className="relative">
                                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                                <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                            </div>
                                            <input
                                                id="search"
                                                name="search"
                                                className="block w-full rounded-md border-0 bg-gray-700 py-1.5 pl-10 pr-3 text-gray-300 placeholder:text-gray-400 focus:bg-white focus:text-gray-900 focus:ring-0 sm:text-sm sm:leading-6"
                                                placeholder="Search"
                                                type="search"
                                                onKeyUp={handleSearchFilterChange}
                                                onChange={handleSearchFilterChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="lg:ml-4 lg:block">
                                    <div className="flex items-center">
                                        <button
                                            type="button"
                                            className="relative inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                            onClick={props.onAddPrayerRequestButtonClick}
                                        >
                                            <PlusIcon className="h-5 w-5" aria-hidden="true" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </Disclosure>
        </>
    );
};